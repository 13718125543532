import React from 'react'
import DefaultLayout from "../layouts/default";
import SEO from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MapContainer, TileLayer, Marker, Polyline, ScaleControl, ZoomControl } from 'react-leaflet'
import { getBounds } from "../utils/bounds"
import randomColor from 'randomcolor';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import colorIcons from "../components/markers";
import pdfIcon from "../images/pdf.svg"
import formIcon from "../images/form.png"


const Track = ({ pageContext }) => {
    const bounds = getBounds([pageContext], pageContext.points);
    return (
        <DefaultLayout>
            <SEO title={pageContext.title}/>
            <div className='flex mb-5'>
                <article className="prose">
                    <h2 className="">{pageContext.title}</h2>
                        <span className="font-semibold">Poziom: </span>
                        {pageContext.levels.map(level => (<span key={level} className="mr-2">{level}</span>))}
                        <br/>
                        <span className="font-semibold">Przedmioty: </span>
                        {pageContext.subjects.map(level => (<span key={level} className="mr-2">{level}</span>))}
                        <br/>
                        <span className="font-semibold">Aktywności: </span>
                        {pageContext.activities.map(activity => (<span key={activity} className="mr-2">{activity}</span>))}
                        <p className='mt-2'>
                            <span className='font-semibold'>Scenariusze do pobrania:</span>
                                {pageContext.scenarios.map(scenario => (
                                    <a key={scenario.name} href={scenario.url} download className='flex mt-1'>
                                        <img src={pdfIcon} alt="pdf" className='h-8 my-auto mr-3'/>
                                        <span>{scenario.name}</span>
                                    </a>
                                ))}
                        </p>
                    <div 
                    dangerouslySetInnerHTML={{__html: pageContext.body}}
                    />
                </article>
                {pageContext.image && 
                    <GatsbyImage 
                    className='ml-4'
                    image={getImage(pageContext.image)}
                    alt={pageContext.title}
                    />
                }
            </div>
            <p className='my-4 flex '>
            <img src={formIcon} alt="pdf" className='h-8 mr-3'/>
                Po przejściu całej trasy, napisz co sądzisz o scenariuszu. Formularz znajdziesz 
                <a 
                    className='ml-1 text-blue-500'
                    href={`https://docs.google.com/forms/d/e/1FAIpQLSdjUWsDyONZEXaMrZZ0h84n0Vt_asmENdb5T07DgWfPkFTUuw/viewform?usp=pp_url&entry.1093999552=${encodeURIComponent(pageContext.title)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >TUTAJ</a>.
            </p>
            <MapContainer
                // style={{height: "300px", width: "700px"}}
                className='w-full h-96'
                bounds={bounds.geometry.coordinates}
                zoomControl={false}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <FullscreenControl position='topright'/>
                <ZoomControl position='topright' />
                <ScaleControl position='bottomright'/>
                {pageContext.points.map((point, idx) => (
                    <Marker 
                        position={point.coordinates}
                        key={point.coordinates}
                        icon={ idx === 0 ? colorIcons.red : colorIcons.blue}
                    />
                ))}
                <Polyline 
                positions={pageContext.coordinates}
                key={String(pageContext.coordinates)} 
                weight="5"
                color={randomColor({
                    seed: pageContext.title,
                    luminosity: "dark",
                })}
                />
            </MapContainer>
        </DefaultLayout>
    )
}

export default Track